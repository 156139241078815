import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import SwiperCore, {
    A11y, Navigation, Pagination, Scrollbar,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import AddToCart from 'Component/AddToCart';
import ProductSliderCard from 'Component/ProductSliderCard';
import { SliderProductsType, SliderStickersType, SliderThemeType } from 'Type/SliderProductsType';
import { startMobile } from 'Util/mcMobileCheck';

import BlackFridayCounterCarouselCard from '../BlackFridayCounterCarouselCard/BlackFridayCounterCarouselCard.component';

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss';
import './ProductSlider.style';

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/create-config-files
export const MIN_SLIDES_FOR_LOOP = 4;

/** @namespace Pwa/Component/ProductSlider/Component */
export class ProductSliderComponent extends PureComponent {
    static propTypes = {
        items: SliderProductsType,
        config: PropTypes.shape({
            breakpoints: PropTypes.object,
            onSlideChange: PropTypes.func,
            onInit: PropTypes.func,
            theme: SliderThemeType,
            stickers: SliderStickersType,
        }).isRequired,
        list: PropTypes.string,
        isBlackFriday: PropTypes.bool,
        price_range: PropTypes.any.isRequired,
        isVariants: PropTypes.bool,
    };

    static defaultProps = {
        items: [],
        list: '',
        isBlackFriday: false,
        isVariants: false,
    };

    state = {
        showLoader: false,
    };

    swiperRef = createRef();

    componentDidMount() {
        const { isVariants } = this.props;
        setTimeout(() => {
            this.setState({ showLoader: true });
        }, 1000);

        if (isVariants) {
            this.intervalId = setInterval(this.adjustSlidesHeight.bind(this), 500);
            window.addEventListener('resize', this.adjustSlidesHeight);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.adjustSlidesHeight);

        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    adjustSlidesHeight = () => {
        const swiperContainer = this.swiperRef.current;

        if (!swiperContainer) {
            return;
        }

        const swiperSlides = swiperContainer.querySelectorAll('.swiper-slide');
        let maxHeight = 0;

        // Resetta le altezze degli elementi
        swiperSlides.forEach((slide) => {
            const slideContent = slide.querySelector('.ProductSliderCard');

            if (slideContent) {
                slideContent.style.height = 'auto';
            }
        });

        swiperSlides.forEach((slide) => {
            const slideContent = slide.querySelector('.ProductSliderCard');

            if (slideContent) {
                const slideHeight = slideContent.offsetHeight;

                if (slideHeight > maxHeight) {
                    maxHeight = slideHeight;
                }
            }
        });

        swiperSlides.forEach((slide) => {
            const slideContent = slide.querySelector('.ProductSliderCard');

            if (slideContent) {
                slideContent.style.height = `${maxHeight}px`;
            }
        });
    };

    renderAddToCart(item) {
        const configurableVariantIndex = -1;
        const quantity = 1;
        const groupedProductQuantity = {};
        const productOptionsData = {};

        return (
            <AddToCart
              product={ item }
              mix={ { block: 'ProductSliderCard', elem: 'AddToCart' } }
              configurableVariantIndex={ configurableVariantIndex }
              quantity={ quantity }
              groupedProductQuantity={ groupedProductQuantity }
              productOptionsData={ productOptionsData }
            />
        );
    }

    render() {
        const {
            items, config: {
                breakpoints, onSlideChange, onInit, theme, stickers,
            },
            list,
            isBlackFriday,
            price_range,
            isVariants,
        } = this.props;
        const { showLoader } = this.state;
        const isSlider = (startMobile() || (items.length > MIN_SLIDES_FOR_LOOP));
        let isbreak = breakpoints;

        if (list === 'third level products variants') {
            isbreak = {
                0: {
                    slidesPerView: isVariants ? 1.5 : 1,
                },
                1024: {
                    slidesPerView: isVariants ? 3.5 : 3,
                },
            };
        }
        const navigationConfig = isSlider ? {
            nextEl: '.ProductSlider-Next',
            prevEl: '.ProductSlider-Prev',
            disabledClass: 'ProductSlider-Off',
        } : {
            nextEl: '.ProductSlider-Next-Disabled',
            prevEl: '.ProductSlider-Prev-Disabled',
            disabledClass: 'ProductSlider-Disabled',
        };

        SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

        if (showLoader) {
            return (
                <div block="ProductSlider" mods={ { Theme: theme } } className={ isVariants ? 'variants' : '' } ref={ this.swiperRef }>
                    <Swiper
                      spaceBetween={ 24 }
                      breakpoints={ isbreak }
                      navigation={ navigationConfig }
                      watchOverflow
                      loop={ items.length >= MIN_SLIDES_FOR_LOOP }
                      scrollbar={ { draggable: true } }
                      onSlideChange={ onSlideChange }
                      onSwiper={ onInit }

                    >
                        { items && items.map((card, i) => (
                            <SwiperSlide key={ card.sku }>
                                { (isBlackFriday)
                                    && (
                                        <BlackFridayCounterCarouselCard
                                          card={ card }
                                          theme={ theme }
                                          stickers={ stickers }
                                          position={ i + 1 }
                                          list={ list }
                                          price={ price_range }
                                        />
                                    ) }
                                { (!isBlackFriday)
                                    && (
                                        <>
                                        <ProductSliderCard
                                          card={ card }
                                          theme={ theme }
                                          stickers={ stickers }
                                          position={ i + 1 }
                                          isVariants={ isVariants }
                                          list={ list }
                                          price={ price_range }
                                        />
                                        { this.renderAddToCart(card) }
                                        </>
                                    ) }
                            </SwiperSlide>
                        )) }

                        { isSlider && <div block="ProductSlider" elem="Next" /> }
                        { isSlider && !isVariants && <div block="ProductSlider" elem="Prev" /> }
                    </Swiper>
                </div>
            );
        }

        return null;
    }
}

export default ProductSliderComponent;
