import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { SliderProductType, SliderStickersType, SliderThemeType } from 'Type/SliderProductsType';

import ProductSliderCard from './ProductSliderCard.component';

export const GTMDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/GTM/GTM.dispatcher'
);

/** @namespace Pwa/Component/ProductSliderCard/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

/** @namespace Pwa/Component/ProductSliderCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    pushProductClick: (product, position, list) => GTMDispatcher.then(
        ({ default: dispatcher }) => dispatcher.pushProductClick(dispatch, product, position, list)
    ),
});
/** @namespace Pwa/Component/ProductSliderCard/Container */
export class ProductSliderCardContainer extends PureComponent {
    static propTypes = {
        card: SliderProductType.isRequired,
        theme: SliderThemeType.isRequired,
        stickers: SliderStickersType.isRequired,
        position: PropTypes.number.isRequired,
        list: PropTypes.string.isRequired,
        pushProductClick: PropTypes.func.isRequired,
        price: PropTypes.any.isRequired,
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
        pushGtmProductClick: this.pushGtmProductClick.bind(this),
    };

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    pushGtmProductClick() {
        const {
            position, card, list, pushProductClick,
        } = this.props;

        pushProductClick(card, position, list);
    }

    render() {
        return (
            <ProductSliderCard
              { ...this.props }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSliderCardContainer);
