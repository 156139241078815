import ContentWrapper from 'Component/ContentWrapper';
import IfStore from 'Component/IfStore';
import Link from 'Component/Link';
import Loader from 'SourceComponent/Loader';
import {
    NoMatchComponent as SourceNoMatch,
} from 'SourceRoute/NoMatch/NoMatch.component';

import Image404 from './Images/404.png';
import Image404ES from './Images/404_es.png';

import './NoMatch.override.style';

/** @namespace Pwa/Route/NoMatch/Component */
export class NoMatchComponent extends SourceNoMatch {
    state = {
        isLoading: true,
    };

    componentDidMount() {
        super.componentDidMount.bind(this);
        setTimeout(() => {
            this.setState({ isLoading: false });
            document.head.ariaHidden = '404';
        }, 3000);
    }

    componentWillUnmount() {
        document.head.removeAttribute('aria-hidden');
    }

    render() {
        const { isLoading } = this.state;

        if (isLoading) {
            return (
                <Loader isLoading={ isLoading } />
            );
        }

        return (
            <main block="NoMatch" aria-label={ __('Page not found') }>
                <ContentWrapper
                  mix={ { block: 'NoMatch' } }
                  wrapperMix={ { block: 'NoMatch', elem: 'Wrapper' } }
                  label={ __('Page Not Found Content') }
                >
                    <IfStore storeCode="default">
                    <img alt="404image" src={ Image404 } />

                    </IfStore>
                    <IfStore storeCode="spain">
                    <img alt="404image" src={ Image404ES } />

                    </IfStore>
                    <h1>
                    { __('Oops, sembra che tu abbia preso una strada diversa!') }
                    </h1>
                    <p block="NoMatch" elem="Subtitle">
                    { __('Non preoccuparti, torniamo insieme al percorso giusto.') }
                    </p>
                    <Link
                      to="/"
                      block="NoMatch"
                      elem="Button"
                      mix={ { block: 'Button' } }
                    >
                        { __('TORNA ALLA PAGINA INIZIALE') }
                    </Link>
                </ContentWrapper>
            </main>
        );
    }
}

export default NoMatchComponent;
