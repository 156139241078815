import BlackFridayCounter from 'Component/BlackFridayCounter/BlackFridayCounter.container';
import DimensionFormatter from 'Component/DimensionFormatter';
import Image from 'Component/Image';
import ImageSticker from 'Component/ImageSticker';
import ProductPrice from 'Component/ProductPrice';
import ProductSliderCardComponent from 'Component/ProductSliderCard/ProductSliderCard.component';
import PromoMask from 'Component/PromoMask';

import 'swiper/swiper.scss';
import './BlackFridayCounterCarouselCard.style.scss';

/** @namespace Pwa/Component/BlackFridayCounterCarouselCard/Component */
export class BlackFridayCounterCarouselCardComponent extends ProductSliderCardComponent {
    renderScontoIcon() {
        const {
            card: {
                mc_black_friday_counter: {
                    discount_image,
                },
            },
        } = this.props;

        const { standard: image } = discount_image || {};

        return image && (
            <div block="ProductSliderCard" elem="ScontoIconWrapper">
                <img
                  block="ProductSliderCard"
                  elem="ScontoIcon"
                  src={ `${image}` }
                  alt="Promo"
                />
            </div>
        );
    }

    renderSlideDetail() {
        const {
            card: {
                mc_black_friday_counter: {
                    is_closed, items_total,
                },
            },
        } = this.props;

        if (is_closed) {
            return this.renderBlackFridayClosed();
        }

        if (items_total > 0) {
            return this.renderBlackFridayCounter();
        }

        return this.renderDescription();
    }

    renderBlackFridayClosed() {
        const {
            card: {
                mc_black_friday_counter: { closed_image },
            },
        } = this.props;

        return (
            <Image
              src={ closed_image }
              mix={ {
                  block: 'Footer',
                  elem: 'Logo',
              } }
            />
        );
    }

    renderBlackFridayCounter() {
        const {
            card: {
                mc_black_friday_counter: blackFriday,
                sku,
            },
        } = this.props;

        return (
            <BlackFridayCounter
              blackFriday={ blackFriday }
              sku={ sku }
              mix={ { block: 'ProductSliderCard', elem: 'BlackFridayCounter' } }
            />
        );
    }

    renderDescription() {
        const {
            card: {
                mc_dimension_height: height,
                mc_dimension_width: width,
                mc_dimension_depth: depth,
                mc_inline_description: description,
            },
        } = this.props;

        return (
            <>
                <div>{ description }</div>
                <DimensionFormatter
                  height={ height }
                  width={ width }
                  depth={ depth }
                />
            </>
        );
    }

    renderPrezzo() {
        const { card: { price_range } } = this.props;

        return (
            <div block="ProductSliderCard" elem="SlidePriceValue">
                <ProductPrice promoPosition="homepageCarouselCont" price={ price_range } reverse />
            </div>
        );
    }

    renderProductImage() {
        const {
            stickers,
            card: {
                sku,
                thumbnail: { url: image_url },
                mc_image_stickers,
            },
        } = this.props;

        const { [stickers]: warranty = [] } = mc_image_stickers || {};

        return this.renderCardLinkWrapper((
            <>
                <Image
                  key={ sku }
                  src={ image_url }
                  ratio="custom"
                  mix={ {
                      block: 'ProductSliderCard',
                      elem: 'SlideImage',
                  } }
                />
                { warranty
                && warranty.map((tag, indice) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ImageSticker tag={ tag } key={ indice } />
                )) }
            </>
        ));
    }

    renderProductName() {
        const {
            card: { name },
        } = this.props;

        return this.renderCardLinkWrapper((
            <p block="ProductSliderCard" elem="SlideTitle">
                { name }
            </p>
        ));
    }

    renderIsComing() {
        const { card: { mc_black_friday_counter: { time_end } } } = this.props;

        return (
            <PromoMask reveal_time={ time_end } />
        );
    }

    renderRevealed() {
        return (
            <>
                { this.renderProductImage() }
                <div block="ProductSliderCard" elem="SlideInfo">
                    <div block="ProductSliderCard" elem="SlideDescription">
                        { this.renderProductName() }
                        <div block="ProductSliderCard" elem="SlideDetail">
                            { this.renderSlideDetail() }
                        </div>
                    </div>
                    <div block="ProductSliderCard" elem="SlidePrice">
                        { this.renderPrezzo() }
                    </div>
                </div>
            </>
        );
    }

    renderCard() {
        const { card: { mc_black_friday_counter: { is_coming } } } = this.props;

        if (is_coming) {
            return this.renderIsComing();
        }

        return this.renderRevealed();
    }

    render() {
        const { card: { mc_black_friday_counter: { discount_code, is_coming } }, theme } = this.props;

        return (
            <div block="ProductSliderCard" mods={ { DiscountCode: discount_code, Theme: theme, IsComing: is_coming } }>
                { this.renderCard() }
            </div>
        );
    }
}

export default BlackFridayCounterCarouselCardComponent;
