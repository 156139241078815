import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import AddToCart from 'Component/AddToCart';
import DimensionFormatter from 'Component/DimensionFormatter';
import FlashSaleIndicator from 'Component/FlashSalesIndicator';
import Image from 'Component/Image';
import ImageSticker from 'Component/ImageSticker';
import Link from 'Component/Link';
import ProductPrice from 'Component/ProductPrice';
import PromoMask from 'Component/PromoMask';
import { SliderProductType, SliderStickersType, SliderThemeType } from 'Type/SliderProductsType';

import 'swiper/swiper.scss';
import './ProductSliderCard.style.scss';

/** @namespace Pwa/Component/ProductSliderCard/Component */
export class ProductSliderCardComponent extends PureComponent {
    static propTypes = {
        card: SliderProductType.isRequired,
        theme: SliderThemeType.isRequired,
        stickers: SliderStickersType.isRequired,
        pushGtmProductClick: PropTypes.func.isRequired,
        isVariants: PropTypes.bool,
    };

    static defaultProps = {
        isVariants: false,
    };

    renderCardLinkWrapper(children, block = '', elem = '', mix = {}) {
        const {
            pushGtmProductClick,
            card: {
                url = '',
                name,
            },
        } = this.props;

        return (
            <Link
              block={ block }
              elem={ elem }
              to={ url }
              aria-label={ name }
              onClick={ pushGtmProductClick }
              mix={ mix }
            >
              { children }
            </Link>
        );
    }

    renderScontoIcon() {
        const {
            card: {
                mc_flashsale: {
                    discount_image,
                },
            },
        } = this.props;

        const { standard: image } = discount_image || {};

        return image && (
            <div block="ProductSliderCard" elem="ScontoIconWrapper">
                <img
                  block="ProductSliderCard"
                  elem="ScontoIcon"
                  src={ `${image}` }
                  alt="Promo"
                />
            </div>
        );
    }

    renderSlideDetail() {
        const {
            card: {
                mc_flashsale: {
                    is_closed, items_total,
                },
            },
        } = this.props;

        if (is_closed) {
            return this.renderFlashSaleClosed();
        }

        if (items_total > 0) {
            return this.renderFlashSaleIndicator();
        }

        return this.renderDescription();
    }

    renderFlashSaleClosed() {
        const {
            card: {
                mc_flashsale: { closed_image },
            },
        } = this.props;

        return (
            <Image
              src={ closed_image }
              mix={ {
                  block: 'Footer',
                  elem: 'Logo',
              } }
            />
        );
    }

    renderFlashSaleIndicator() {
        const {
            card: {
                mc_flashsale: flashsale,
                sku,
            },
        } = this.props;

        return (
            <FlashSaleIndicator
              flashsale={ flashsale }
              sku={ sku }
              mix={ { block: 'ProductSliderCard', elem: 'Flashsale' } }
            />
        );
    }

    renderDescription() {
        const {
            card: {
                mc_dimension_height: height,
                mc_dimension_width: width,
                mc_dimension_depth: depth,
                mc_inline_description: description,
            },
        } = this.props;

        return (
            <>
                <div>{ description }</div>
                <DimensionFormatter
                  height={ height }
                  width={ width }
                  depth={ depth }
                />
            </>
        );
    }

    renderPrezzo() {
        const { card: { price_range } } = this.props;

        return (
            <div block="ProductSliderCard" elem="SlidePriceValue">
                <ProductPrice price={ price_range } reverse />
            </div>
        );
    }

    renderProductImage() {
        const {
            stickers,
            card: {
                sku,
                small_image: { url: image_url },
                mc_image_stickers,
            },
        } = this.props;

        const { [stickers]: warranty = [] } = mc_image_stickers || {};

        return this.renderCardLinkWrapper((
            <>
                <Image
                  key={ sku }
                  src={ image_url }
                  ratio="custom"
                  mix={ {
                      block: 'ProductSliderCard',
                      elem: 'SlideImage',
                  } }
                />
                { warranty
                && warranty.map((tag, indice) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ImageSticker tag={ tag } key={ indice } />
                )) }
            </>
        ));
    }

    renderProductName() {
        const {
            card: { name },
        } = this.props;

        return this.renderCardLinkWrapper((
            <p block="ProductSliderCard" elem="SlideTitle">
                { name }
            </p>
        ));
    }

    renderIsComing() {
        const { card: { mc_flashsale: { time_end } } } = this.props;

        return (
            <PromoMask reveal_time={ time_end } />
        );
    }

    renderRevealed() {
        const { isVariants, card } = this.props;

        if (isVariants) {
            return (
                <div
                  block="CardSlider"
                  elem="Option"
                >
                    <a href={ card.url }>
                        <img
                          src={ card.image ? card.image.url : card.small_image.url }
                          alt={ card.name }
                        />
                    </a>
                    <div
                      className="CardVariantsContainer"
                    >
                        <div className="CardVariants">
                            <div style={ { position: 'static' } }>
                                <a href={ card.url } style={ { textDecoration: 'none' } }>
                                    <h3>
                                        { card.name }
                                    </h3>
                                    <h2>
                                        { card.mc_inline_description }
                                    </h2>
                                    <h2 className="cardMeasurementsVariant">
                                        cm.&nbsp;
                                        { card.mc_dimension_width }
                                        &nbsp;x&nbsp;
                                        { card.mc_dimension_depth }
                                        &nbsp;x&nbsp;
                                        { card.mc_dimension_height }
                                    </h2>
                                </a>
                                { /* { this.renderAddToCart(card) } */ }
                            </div>
                            <div block="PriceCollection">
                                <ProductPrice
                                  promoPosition="top-miglior-prezzo"
                                  price={ card.price_range }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <>
                { this.renderProductImage() }
                <div block="ProductSliderCard" elem="SlideInfo">
                    <div block="ProductSliderCard" elem="SlideDescription">
                        { this.renderProductName() }
                        <div block="ProductSliderCard" elem="SlideDetail">
                            { this.renderSlideDetail() }
                        </div>
                    </div>
                    <div block="ProductSliderCard" elem="SlidePrice">
                        { this.renderPrezzo() }
                        { this.renderScontoIcon() }
                    </div>
                </div>
            </>
        );
    }

    renderAddToCart(item) {
        const configurableVariantIndex = -1;
        const quantity = 1;
        const groupedProductQuantity = {};
        const productOptionsData = {};

        return (
            <AddToCart
              product={ item }
              mix={ { block: 'ProductSliderCard', elem: 'AddToCart' } }
              configurableVariantIndex={ configurableVariantIndex }
              quantity={ quantity }
              groupedProductQuantity={ groupedProductQuantity }
              productOptionsData={ productOptionsData }
            />
        );
    }

    renderCard() {
        const { card: { mc_flashsale: { is_coming } } } = this.props;

        if (is_coming) {
            return this.renderIsComing();
        }

        return this.renderRevealed();
    }

    render() {
        const { card: { mc_flashsale: { discount_code, is_coming } }, theme } = this.props;

        return (
            <div block="ProductSliderCard" mods={ { DiscountCode: discount_code, Theme: theme, IsComing: is_coming } }>
                { this.renderCard() }
            </div>
        );
    }
}

export default ProductSliderCardComponent;
