import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './Swogo.style.scss';

/** @namespace Pwa/Component/Swogo/Component */
export class SwogoComponent extends PureComponent {
    static propTypes = {
        bundleId: PropTypes.number.isRequired,
    };

    render() {
        const { bundleId } = this.props;

        return (
            <div id={ `swogo-bundle-${bundleId}` } className="swogo-box" />

        );
    }
}

export default SwogoComponent;
