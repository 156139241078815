import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { BlackFridayCounterType } from 'Type/BlackFridayCounterType';
import { countdown } from 'Util/Countdown';

import BlackFridayCounter from './BlackFridayCounter.component';

/** @namespace Pwa/Component/BlackFridayCounter/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    promo: state.BlackFridayCounterPromoReducer.promo,
});

/** @namespace Pwa/Component/BlackFridayCounter/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // ADD dispatch se dovesse servire vedi ProductList.container.js
});

// successivamente sostituire flashsale con blackfriday
/** @namespace Pwa/Component/BlackFridayCounter/Container */
export class BlackFridayCounterContainer extends PureComponent {
    // prende le props direttamente da this.props, in cui sono i dati ottenuti tramite chiamata GraphQl
    static propTypes = {
        blackFriday: BlackFridayCounterType.isRequired,
        sku: PropTypes.string.isRequired,
        isInfoIcon: PropTypes.bool,
        isCompactVersion: PropTypes.bool,
        mix: PropTypes.object,
        promo: PropTypes.array.isRequired,
    };

    static defaultProps = {
        isInfoIcon: false,
        isCompactVersion: false,
        mix: {},
    };

    state = {
        countdown: {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        },
    };

    componentDidMount() {
        const { blackFriday: { time_end } } = this.props;

        if (time_end !== null) {
            this.carouselInterval = countdown(time_end, (countdown) => this.setState({ countdown }));
        }
    }

    componentWillUnmount() {
        clearTimeout(this.carouselInterval);
    }

    render() {
        const {
            blackFriday, sku, isInfoIcon, isCompactVersion, mix, promo,
        } = this.props;
        const { countdown } = this.state;

        const { discount_code: rt_discount_code, items_left: rt_item_left } = promo[sku] || {};
        const { discount_code } = blackFriday;

        const rt_blackFriday = (rt_discount_code === discount_code) ? {
            ...blackFriday,
            items_remaining: rt_item_left,
        } : blackFriday;

        return (
            <BlackFridayCounter
              mix={ mix }
              sku={ sku }
              blackFriday={ rt_blackFriday }
              countdown={ countdown }
              isInfoIcon={ isInfoIcon }
              isCompactVersion={ isCompactVersion }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlackFridayCounterContainer);
